import React from 'react'
import '../index.css'
import './interiorCleaning.css'

function InteriorCleaning() {
    const maintenanceClick = () => {
        document.querySelectorAll("a[href='#/maintenance']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }
    return (
        <div className='carousel-wrapper'>
            <span className='carousel-line1'>Commercial Facilities the Ryne way!</span>
            <span className='carousel-line2'>Turn the hassle of keeping your facilities clean and tidy to hassle free...</span>
            <span className='carousel-line3'>Let the experts at Ryne take care of this burden. Cleaning is our specialty.<br />As soon as you start using Ryne, hygiene and sanitary will have a whole new definition.</span>
            <span className='carousel-button' onClick={maintenanceClick}>Read more</span>
        </div>
    )
}

export default InteriorCleaning