import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBAES_API_KEY,
    authDomain: process.env.REACT_APP_FIREBAES_ATUH_DOMAIN,
    projectId: "rynewebsite",
    storageBucket: process.env.REACT_APP_FIREBAES_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBAES_MESSAGEING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBAES_APP_ID
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
    projectStorage,
    projectFirestore,
    timestamp
};