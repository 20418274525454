import React from 'react';
import '../construction.css';

const MidConstruction = () => {
  return (
    <div className='page-content-wrapper'>
      <div className='construction-card-wrapper'>

        <div className='construction-card construction-mobile-top'>
          <span className='construction-card-image mid-construction'></span>
        </div>

        <div className='construction-card construction-mobile-bottom'>
          <span className='construction-card-title'>Mid Construction</span>
          <span className='construction-card-verbiage'>
            We have a fully trained staff to clean and maintain your construction sites, to keep it safe. This helps to seamlessly move from phase to phase without any interruption.
          </span>
          {/* <span className='construction-card-verbiage'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
              </span> */}
        </div>
      </div>
    </div>
  )
}

export default MidConstruction