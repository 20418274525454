import React from 'react'
import './admin.css';
import UploadImages from '../uploadImages/UploadImages'

const Admin = () => {
    return (
        <div className='page-container'>
            <div className='page-banner blue about-page-banner'>
                <div className='page-banner-image-wrapper'>
                    <div className='page-banner-image'></div>
                </div>
                <div className='page-banner-verbiage'>
                    <span className='page-banner-h1'>Admin Stuff</span>
                    <span className='page-banner-h2'>This page will be taken care of soon... For now you can use it...</span>
                </div>
            </div>

            <div className='page-content'>
                <UploadImages />
            </div>
        </div>
    )
}

export default Admin