import React from 'react'
import '../pages.css';
import './about.css';

import { AboutContainer, Mission } from './containers'

const About = () => {
  return (
    <div className='page-container'>
      <div className='page-banner blue about-page-banner'>
        <div className='page-banner-image-wrapper'>
          <div className='page-banner-image banner-about'></div>
        </div>

        <div className='page-banner-verbiage'>
          <span className='page-banner-h1'>About Ryne Cleaning</span>
          <span className='page-banner-h2'>This is who we are. This is what we have learned with years of experience. It is how we got to where are at. It is about our commitment and our mission. Find out how you can put our expertise to work for you!
          </span>
        </div>
      </div>
      <div className='page-content'>
        <AboutContainer />
        <Mission />
      </div>
    </div>
  )
}

export default About