import React from 'react'
import '../maintenance.css';

const LaundryRooms = () => {
  return (
    <div className='maintenance-card'>
      <span className='maintenance-card-image laundry-room'></span>
      <span className='maintenance-card-title'>Laundry Rooms</span>
      <span className='maintenance-card-verbiage'>
        Laundry rooms and other amenity rooms are often times neglected and put on the back burner. Doing so is often the source of rodents and other infestations. At Ryne these areas take up as much space as any other area keeping it spotless clean, fresh and neat.
      </span>
    </div>
  )
}

export default LaundryRooms