import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaBars } from "react-icons/fa";

import './navbar.css'


const Navbar = () => {
  const [navActive, setNavActive] = useState(false);
  const [transNav, setTransNav] = useState(true);

  const ActivateNav = () => {
    if (window.scrollY > 80) {
      setNavActive(true);
    } else {
      setNavActive(false);
    }
  }

  const transNavClick = () => {
    setTimeout(() => {
      document.location.href.split('/')[4] === "home" || document.location.href.split('/')[4] === "" || document.location.href.split('/')[3] === "" ?
        setTransNav(true) :
        setTransNav(false);
    }, 10);
  }

  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, []);

  window.addEventListener('scroll', ActivateNav);
  window.addEventListener('load', transNavClick);


  return (
    <div className={navActive || transNav === false ? 'navbar-container active' : 'navbar-container'}>
      <div className='navbar-wrapper'>
        <div className='navbar-mobile-wrapper'>
          <FaBars className='navbar-mobile-toggle' onClick={toggleNav} />
          <Link className='navbar-logo' to='/' onClick={transNavClick}>Cleaning & Maintenance</Link>
        </div>
        <div className={toggleMenu ? 'toggle-on navbar-active-animate' : 'navbar-active-animate'}>
          <Link className='navbar-link' to='/home' onClick={() => { transNavClick(); toggleNav(); }}>Home</Link>
          <Link className='navbar-link' to='/about' onClick={() => { transNavClick(); toggleNav(); }}>About</Link>
          <Link className='navbar-link' to='/maintenance' onClick={() => { transNavClick(); toggleNav(); }}>Maintenance</Link>
          <Link className='navbar-link' to='/construction' onClick={() => { transNavClick(); toggleNav(); }}>Construction</Link>
          <Link className='navbar-link' to='/services' onClick={() => { transNavClick(); toggleNav(); }}>Services</Link>
          <Link className='navbar-link' to='/contact' onClick={() => { transNavClick(); toggleNav(); }}>Contact</Link>
          <a className='navbar-link login' href="https://ryne.zuperhub.com/login">Client Login</a>
        </div>
      </div>
    </div >
  )
}

export default Navbar