import React from 'react';
import '../construction.css';

const Renovation = () => {
  return (
    <div className='page-content-wrapper'>
      <div className='construction-card-wrapper'>

        <div className='construction-card construction-mobile-top'>
          <span className='construction-card-image renovation'></span>
        </div>

        <div className='construction-card construction-mobile-bottom'>
          <span className='construction-card-title'>Apartment Renovation</span>
          <span className='construction-card-verbiage'>
            Whether it is a fresh coat of paint, redoing or treating your floors, doing a partial or full fledged renovation, it is always exciting and at times even necessary. However, the process creates an environment that is unfit for living. Breaking open old drywall creates a dust that may release toxins. The materials being used to paint and do other treatments contain unhealthy chemicals that need to be cleaned once everything settles.
          </span>
          <span className='construction-card-verbiage'>
            The associates at Ryne are experienced in such scenarios. We enter your premises with the proper gear and equipment. We get everything back in order so that your place is fresh and new as you anticipated it.
          </span>
        </div>
      </div>
    </div>
  )
}

export default Renovation