import React from 'react'
import '../index.css';
import './snow.css';


const Snow = () => {
    const maintenanceClick = () => {
        document.querySelectorAll("a[href='#/maintenance']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }
    return (
        <div className='carousel-wrapper'>
            <span className='carousel-line1'>Snow Removal on time every time!</span>
            <span className='carousel-line2'>While snow is one of nature's beauties and relaxing dexterities, the cleaning process can be burdensome.!</span>
            <span className='carousel-line3'>As soon as the snow stops falling, Ryne takes care of the burden leaving you and your tenants to deal with the cozy and fuzzy conditions snow creates...</span>
            <span className='carousel-button' onClick={maintenanceClick}>Read more</span>
        </div>
    )
}

export default Snow