import React from 'react'
import '../maintenance.css';

const Sanitation = () => {
    return (
        <div className='maintenance-card'>
            <span className='maintenance-card-image sanitaion'></span>
            <span className='maintenance-card-title'>Sanitaion</span>
            <span className='maintenance-card-verbiage'>
                Besides for keeping the exterior of the building clean and the garbage looking nice and tidy, garbage has a whole slew of guidelines. Some local municipalities have recycling policies and some localities require garbage in a certain spot for pickup. Ryne takes care of these and more.
            </span>
        </div>
    )
}

export default Sanitation