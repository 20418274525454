import React from 'react'
import '../services.css';

const Unanticipated = () => {
    return (
        <div className='services-card'>
            <span className='services-card-image unanticipated'></span>
            <span className='services-card-title'>Unanticipated Events</span>
            <span className='services-card-verbiage'>
                Unanticipated issues are almost inevitable. It happens to the best of us. It is not alway something that can be avoided but can almost always be fixed. We will make sure the area is clean and hygienic avoiding it from becoming a habitable area for mold and other toxins.
            </span>
        </div>
    )
}

export default Unanticipated