import React from 'react'
import ImageGrid from '../../uploadImages/ImageGrid';
import '../home.css'


const RecentProjects = () => {
    return (
        <>
            <ImageGrid />
        </>

    )
}

export default RecentProjects