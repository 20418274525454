import React, { useEffect } from 'react';
import useStorage from '../../hooks/useStorage';


const ProgressBar = ({ file, setFile, title, description }) => {
    const { progress, url } = useStorage(file, title, description);
    useEffect(() => {
        if (url) {
            setFile(null);
        }
    }, [url, setFile]);

    return (
        <div className="progress-bar"></div>
    );
}

export default ProgressBar;