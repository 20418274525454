import React from 'react'
import '../maintenance.css';

const Exterior = () => {
  return (
    <div className='maintenance-card'>
      <span className='maintenance-card-image street'></span>
      <span className='maintenance-card-title'>Exterior Areas</span>
      <span className='maintenance-card-verbiage'>
        Exterior Areas include cleaning the adjacent sidewalks and taking care of the garbage areas. We prep the garbage for pickup in accordance to the local sanitation guidelines. We make sure these areas are safe, clean and inviting. It is easy to spot a building being taken care of by Ryne.
      </span>
    </div>
  )
}

export default Exterior