import React from 'react'
import '../index.css';
import './garbage.css'

const Garbage = () => {
  const maintenanceClick = () => {
    document.querySelectorAll("a[href='#/maintenance']")[0].click();
    setTimeout(() => {
        document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
    }, 10);
  }
  return (
    <div className='carousel-wrapper'>
      <span className='carousel-line1'>Exterior Building Cleaning</span>
      <span className='carousel-line2'>Sanitation schedules and keeping your adjacent sidewalk clean and garbage free, can be removed from your todo list. </span>
      <span className='carousel-line3'>Besides for keeping your area clean and hygienic, we at Ryne take these tasks very serious so that you don't have to deal with unnecessary penalties and headaches.</span>
      <span className='carousel-button' onClick={maintenanceClick}>Read more</span>
    </div>
  )
}

export default Garbage