import React from 'react';
import useFirestore from '../../hooks/useFirestore';

const ImageGrid = ({ setSelectedImg }) => {
    const { docs } = useFirestore('images');

    return (
        <>
            {docs && docs.map(doc => (
                <div className='recent-card' key={doc.id} onClick={() => setSelectedImg(doc.url)}>
                    <img src={doc.url} alt="uploaded pic" />
                    <div className='recent-card-hover'>
                        <div className='recent-card-text-wrapper'>
                            <span className='recent-title'>{doc.title}</span>
                            <span className='recent-description'>{doc.description}</span>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ImageGrid;