import React from 'react';
import { FaRegPaperPlane, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import './contact.css';

const Contact = () => {
  let nameFullName = 'entry.14124554';
  let nameEmail = 'entry.2059103420';
  let namePhone = 'entry.1673167432';
  let nameMessage = 'entry.324555656';
  let submitAction = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeSE3Jz11RQ5T_x8sdK2epjMINtbMGrQB2SKivfJfy9wvLrpA/formResponse';

  return (
    <div className='page-container'>
      <div className='page-banner blue about-page-banner'>
        <div className='contact-banner-van'></div>
        <div className='page-banner-verbiage'>
          <span className='page-banner-h1'>Contact us</span>
          <span className='page-banner-h2'>Are you in NYC and surrounding area? Do not hesitate. Get in touch with us. Drop us a line via email, use the provided form below, or give us a call at any time.</span>
        </div>
      </div>

      <div className='page-content'>
        <div className='page-content-wrapper'>
          <div className='contact-wrapper'>
            <div className='contact-from'>
              {/* <form method='post' action={submitAction} id='frmGoogleForms' name='frmGoogleForms'>
                <input type='text' name={nameFullName} placeholder='Full Name' required />
                <input type='tel' name={namePhone} placeholder='Phone 123-456-7890' required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
                <input type='email' name={nameEmail} placeholder='Email Address' required />
                <textarea name={nameMessage} placeholder='Message'></textarea>
                <button>Send Message</button>
              </form> */}

              <form action="https://formsubmit.co/info@rynecleaning.com" method="POST">
                <input type="text" name="name" placeholder='Full Name' required />
                <input type="text" tel="name" placeholder='Phone 123-456-7890' required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
                <input type="email" name="email" placeholder='Email Address' required />
                <textarea name='message' placeholder='Message'></textarea>
                <input type="hidden" name='_next' value='https://rynecleaning.com/#/contact' />
                <button type="submit">Send</button>
              </form>

            </div>
            <div className='contact-info'>
              <span className='contact-title'>Contact info</span>
              <span className='contact-row address'><FaRegPaperPlane /> 199 Lee Avenue | Ste 998 | Brooklyn, NY 11211</span>
              <span className='contact-row phone'><FaPhoneAlt />(718) 427-2111</span>
              <span className='contact-row phone'><FaRegEnvelope />info@rynecleaning.com</span>
            </div>
          </div>
        </div>
      </div>

      <script src='https://smtpjs.com/v3/smtp.js'></script>

    </div>
  )
}

export default Contact