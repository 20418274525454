import React from 'react'
import '../maintenance.css';

const Office = () => {
    return (
        <div className='maintenance-card'>
            <span className='maintenance-card-image office'></span>
            <span className='maintenance-card-title'>Scheduled Office Cleaning</span>
            <span className='maintenance-card-verbiage'>
                This includes desks, conference rooms, cafeterias, lavatories, storage areas etc. We work on your schedule avoiding any interruption during your hours of operation. Offices hygiene are often times overlooked. Let Ryne take care of this priority for you.
            </span>
        </div>
    )
}

export default Office