import React from 'react'
import '../about.css';

const AboutContainer = (param1, param2) => {
  return (
    <div className='page-content-wrapper'>
      <div className='about-card-wrapper'>
        <div className='about-card about-mobile-bottom'>
          <span className='about-card-title'>About Us</span>
          <span className='about-card-verbiage'>
            We're a janitorial and building maintenance company located in Brooklyn NY. We are a driven team and steadily evolve looking for opportunities and innovations. We started out as a small group that would come into a building once or twice a week and get it in order and freshened up. With time we grew into an actual company and running a sophisticated scheduling system with an array of services. We have experienced groups on the road throughout the day. As a side note, we have used little to any social media exposure. Most of our marketing and sales are by existing clients referring new potential business. While our customers are first and we are eager to take action in the event we receive constructive criticism, it is our greatest compliment to be referred to companies by our own clients. It is what drives us and it is what reminds us to never ever let a customer down. To keep a portfolio of satisfied clients taking away as much stress as possible pertaining to anything maintenance.
          </span>
          <span className='about-card-verbiage'></span>
        </div>

        <div className='about-card about-mobile-top'>
          <span className='about-card-image one'></span>
        </div>
      </div>
    </div>
  )
}

export default AboutContainer