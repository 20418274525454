import React from 'react';
import '../construction.css';

const PostConstruction = () => {
  return (
    <div className='page-content-wrapper'>
      <div className='construction-card-wrapper'>
        <div className='construction-card construction-mobile-bottom'>
          <span className='construction-card-title'>Post Construction</span>
          <span className='construction-card-verbiage'>
            Building construction is not for the faint of heart. It requires a great skill set and even more so great endurance. It is a process that takes time and a huge workload. When the main project is finally done, the process of punch lists and other peripherals start. The building is finally done and the apartments are being showcased and are making their debut. Nevertheless, to really impress a potential customer, the area needs to actually feel new, fresh and accommodating.
          </span>
          <span className='construction-card-verbiage'>
            It's what Ryne specialize in. After the painters floor guys and low voltage people move out, Ryne moves in with a whole new set of equipment to give the place its final sparkle. The Ryne touch is what you need to impress your viewers.
          </span>
        </div>

        <div className='construction-card construction-mobile-top'>
          <span className='construction-card-image post-constuction'></span>
        </div>
      </div>
    </div>
  )
}

export default PostConstruction