import React from 'react'
import '../index.css'
import './office.css'

const Office = () => {
    const maintenanceClick = () => {
        document.querySelectorAll("a[href='#/maintenance']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }
    return (
        <div className='carousel-wrapper'>
            <span className='carousel-line1'>Office Cleaning done right!</span>
            <span className='carousel-line2'>Keep your office space inviting and exciting!</span>
            <span className='carousel-line3'>Maintaining clean desks, conference rooms, cafeteria and lavatories are vital for a productive and sustainable work environment.</span>
            <span className='carousel-button' onClick={maintenanceClick}>Read more</span>
        </div>
    )
}

export default Office