import React from 'react'
import '../pages.css';
import './services.css';


import { Apartment, Commercial, MoveIn, Party, Unanticipated } from './containers'

const Services = () => {
  return (
    <div className='page-container'>
      <div className='page-banner orange about-page-banner'>
        <div className='page-banner-image-wrapper'>
          <div className='page-banner-image banner-otherServices'></div>
        </div>
        <div className='page-banner-verbiage'>
          <span className='page-banner-h1'>Other Cleaning Services</span>
          <span className='page-banner-h2'>
            No job is too big or too small. We can handle anything you throw at us. Whether it is freshening up your place, do a scheduled deep cleaning or cleaning up after a mess. We do it all!
          </span>
        </div>
      </div>

      <div className='page-content'>
        <div className='page-content-wrapper'>
          <div className='maintenance-card-wrapper '>
            <Apartment />
            <MoveIn />
            <Commercial />
            <Party />
            <Unanticipated />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services