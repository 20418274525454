import React from 'react'
import '../services.css';

const Party = () => {
    return (
        <div className='services-card'>
            <span className='services-card-image post-party'></span>
            <span className='services-card-title'>Post Hosting</span>
            <span className='services-card-verbiage'>
                While hosting guests or throwing a party is most definitely exciting and fun. The mess it leaves behind however, is not so much so. Give Ryne a call. We will take care of the messy part of the party so that you stay with great memories. Your place will look clean and fresh better than before.
            </span>
        </div>
    )
}

export default Party