import React from 'react'
import '../maintenance.css';

const CommonAreas = () => {
    return (
        <div className='maintenance-card'>
            <span className='maintenance-card-image common-areas'></span>
            <span className='maintenance-card-title'>Common Areas</span>
            <span className='maintenance-card-verbiage'>
                Common areas are building areas that are shared with all occupants. These may include and not limited to staircases, elevators, hallways or corridors, entrances etc. While keeping these areas clean and clutter free is the primary focus, we make sure everything is safe and in working order.
            </span>
        </div>
    )
}

export default CommonAreas