import React from 'react'
import '../maintenance.css';

const Lighting = () => {
    return (
        <div className='maintenance-card'>
            <span className='maintenance-card-image lighting'></span>
            <span className='maintenance-card-title'>Lighting Inspection and care</span>
            <span className='maintenance-card-verbiage'>
                Other than taking care of general common area needs, we have the right equipment handy for hard to reach areas such as light fixtures, tall windows and other areas difficult to get to. We take care of these ares safely proficiently and professionally.
            </span>
        </div>
    )
}

export default Lighting