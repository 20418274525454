import React from 'react'

const mission = () => {
  return (
    <div className='page-content-wrapper'>
      <div className='about-card-wrapper'>
        <div className='about-card about-mobile-top'>
          <span className='about-card-image two'></span>
        </div>

        <div className='about-card about-mobile-bottom'>
          <span className='about-card-title'>Our Mission</span>
          <span className='about-card-verbiage'>
            It is a commitment we have to every client. You are the reason we made it to this point. Your loyalty is not a one way deal. We  surpass expectations, and perfection is what we thrive on. When you need us, we will make sure to answer your call, and make the building maintenance aspect of your business ventures as seamless and as stress free as possible. You will never regret choosing our teams to work your properties.
          </span>
          <span className='about-card-verbiage'>
            We are on a mission to make property owners and developers content... It is a great undertaking but we are confident that our services will have a positive impact.
          </span>
        </div>
      </div>
    </div>
  )
}

export default mission