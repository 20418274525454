import React from 'react'
import '../index.css';
import './renovation.css';

const Renovation = () => {
    const constructionClick = () => {
        document.querySelectorAll("a[href='#/construction']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }
    return (
        <div className='carousel-wrapper'>
            <span className='carousel-line1'>You're done with your renovation!</span>
            <span className='carousel-line2'>Renovating turns your old place into a new, exciting and vibrant area. Keep it that way. Don't let the anxiety and stress of making the place livable again get in the way.</span>
            <span className='carousel-line3'>We at ryne will add the final sparkle to your new precious place.</span>
            <span className='carousel-button' onClick={constructionClick}>Read more</span>
        </div>
    )
}

export default Renovation