import React from 'react'
import '../services.css';

const MoveIn = () => {
    return (
        <div className='services-card'>
            <span className='services-card-image movein'></span>
            <span className='services-card-title'>Move In</span>
            <span className='services-card-verbiage'>
                While the previous occupants may have left your new place nice and clean, you would still need a professional job where an experienced staff will make the place feel officially yours. We are familiar with the right detergents and materials for all surfaces to make sure it is clean and healthy.
            </span>
        </div>
    )
}

export default MoveIn