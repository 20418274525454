import React from 'react'
import '../pages.css';
import './maintenance.css';


import { Basement, CommonAreas, Exterior, Glass, Snow, LaundryRooms, Sanitation, Lighting, Office } from './containers'

const Maintenance = () => {
  return (
    <div className='page-container'>
      <div className='page-banner blue about-page-banner'>
        <div className='page-banner-image-wrapper'>
          <div className='page-banner-image banner-maintenance'></div>
        </div>
        <div className='page-banner-verbiage'>
          <span className='page-banner-h1'>Property Maintenance</span>
          <span className='page-banner-h2'>Keeping your facilities clean and up to par is our day job. It's what we specialize in. See the regular maintenance services we offer while following the same quality control for all.</span>
        </div>
      </div>

      <div className='page-content'>
        <div className='page-content-wrapper'>
          <div className='maintenance-card-wrapper '>
            <CommonAreas />
            <Basement />
            <Exterior />
            <Glass />
            <Snow />
            <LaundryRooms />
            <Sanitation />
            <Lighting />
            <Office />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Maintenance