import React from 'react'
import '../maintenance.css';

const Glass = () => {
  return (
    <div className='maintenance-card'>
      <span className='maintenance-card-image glass'></span>
      <span className='maintenance-card-title'>Glass Surfaces Treatment</span>
      <span className='maintenance-card-verbiage'>
        Glass as well as other meticulous surfaces require special cleaning materials. These surfaces often times need to be cleaned more thoroughly with an extra diligence. Besides for keeping an array of special cleaning materials and equipment, we put a lot of emphasis on these areas.
      </span>
    </div>
  )
}

export default Glass