import React from 'react'
import '../services.css';

const Commercial = () => {
    return (
        <div className='services-card'>
            <span className='services-card-image commercial'></span>
            <span className='services-card-title'>Commercial Cleaning</span>
            <span className='services-card-verbiage'>
                No matter the size of your facility or its functionality. We will create a plan that suits your needs and fits your schedule to keep your faculty up to par hygienic and inviting. With so many happy and consistent clients you can be sure we know the tricks of the trade and so you can rely on Ryne.
            </span>
        </div>
    )
}

export default Commercial