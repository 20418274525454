import React from 'react';
import './construction.css';

import { Renovation, PostConstruction, MidConstruction, Inspection } from './containers'

const Construction = () => {
    return (
        <div className='page-container'>
            <div className='page-banner blue about-page-banner'>
                <div className='page-banner-image-wrapper'>
                    <div className='page-banner-image banner-postConstruction'></div>
                </div>
                <div className='page-banner-verbiage'>
                    <span className='page-banner-h1'>Post Construction</span>
                    <span className='page-banner-h2'>We understand the post constructions stress. We have years of experience dealing with developers and contractors. You will be pleasantly surprised with our services. See our specialties and how you can benefit from it.

                    </span>
                </div>
            </div>

            <div className='page-content'>
                <Renovation />
                <PostConstruction />
                <MidConstruction />
                <Inspection />
            </div>
        </div>
    )
}

export default Construction