import React from 'react'
import '../index.css';
import './construction.css';

const Construction = () => {
    const constructionClick = () => {
        document.querySelectorAll("a[href='#/construction']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }
    return (
        <div className='carousel-wrapper'>
            <span className='carousel-line1'>So you just finished Construction!</span>
            <span className='carousel-line2'>Your apartments are done. Everything is in place. Now you need to impress viewers and potential tenants.</span>
            <span className='carousel-line3'>A clean and dust free environment is a must but adding the Ryne perfection, and creating a clean, fresh and pleasant place, adds the so desperately needed final touch.</span>
            <span className='carousel-button' onClick={constructionClick}>Read more</span>
        </div>
    )
}

export default Construction