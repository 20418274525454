import React from 'react'
import '../maintenance.css';

const Basement = () => {
    return (
        <div className='maintenance-card'>
            <span className='maintenance-card-image basement'></span>
            <span className='maintenance-card-title'>Basement Cleaning and Inspection</span>
            <span className='maintenance-card-verbiage'>
                Often times basements houses equipment, plumbing and electrical rooms. We clean these areas as well as inspecting them on a regular basis for leaks or other obvious malfunction, and so the necessary managements are notified before any damage occurs.
            </span>
        </div>
    )
}

export default Basement