import React from 'react'
import Carousel from '../carousel/Carousel'
import { FaRegBuilding, FaHome, } from "react-icons/fa";

import { RecentProjects } from './containers';

import './home.css'

const Home = () => {
  const contactClick = () => {
    document.querySelectorAll("a[href='#/contact']")[0].click();
    setTimeout(() => {
      document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
    }, 10);
  }
  return (

    <>
      <Carousel />
      <div className=''>
        <div className='home-entrance'></div>

        <div className='home-bottom-banner'>
          <div className='home-bottom-banne-wrapper'>
            <span className='home-bottom-banner-text'>Own or manage rental properties in NY? Contact us for a free no obligation quote today! </span>
            <span className='home-bottom-banner-button' onClick={contactClick}>Get a free consultation</span>
          </div>
        </div>

        <div className='home-peripherals'>
          <div className='page-content-wrapper'>
            <div className='home-cards-wrapper'>
              <div className='home-card'>
                <span className='home-card-title'><FaRegBuilding />Construction</span>
                <span className='home-card-verbiage'>
                  Post construction cleaning is one of our specialties. It is what our experience associates excel in. We run
                  a sophisticated scheduling system so that we can schedule our jobs accordingly. We get in touch with the
                  project manager to get a detail rundown of what needs to get done, and the clock starts ticking. Perfection
                  is what we focus on but speed is our driver. <br /><br />We don't miss a speck while at the same time making
                  sure the clients can move on with the project fast. We do this by employing large teams of highly motivated
                  and experienced individuals.
                </span>
              </div>

              <div className='home-card'>
                <span className='home-card-title'><FaHome />Your Home</span>
                <span className='home-card-verbiage'>
                  We go back and forth the drawing board. We sketch, approve, cancel and do it all over again. So many heartaches
                  and so many excitements. The ordeal is an emotional roller coaster.
                  We pour our hearts and souls into the place we will call our safe haven, our home. The joy of completing such a
                  project is indescribable. It is truly a milestone. We're now ready to make it official. We are about to move
                  into our so well deserved home but soon realize the most important item is missing. There is no way for us to
                  live in a place full of dust and the mess construction leaves behind. TAKE A DEEP BREATH. Relax! It's where Ryne
                  shines. Give us a call and get your stuff ready. You will be moving in before you know it.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='home-mid-section'>
          <div className='home-mid-section-divider'>
            <div className='home-mid-section-veriage-container'>
              <span className='home-mid-section-veriage'>Recent news and some of our projects.</span>
              <span className='home-mid-section-veriage'>See what we are up to and how we can help you.</span>
            </div>
          </div>
          <div className='page-content-wrapper'>
            <div className='recent-cards'>
              <RecentProjects />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home