import React from 'react';

import { Navbar, Home, About, Services, Contact, Maintenance, Construction, Footer, Admin } from './componenets'
import { Route, Routes } from 'react-router-dom';

// import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} ></Route>
        <Route path="/home" element={<Home />} ></Route>
        <Route path="/about/" element={<About />} ></Route>
        <Route path="/maintenance" element={<Maintenance />} ></Route>
        <Route path="/Construction" element={<Construction />} ></Route>
        <Route path="/services" element={<Services />} ></Route>
        <Route path="/about" element={<About />} ></Route>
        <Route path="/contact" element={<Contact />} ></Route>
        <Route path="/admin" element={<Admin />} ></Route>
      </Routes>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default App;
