import React from 'react'
import '../maintenance.css';

const Snow = () => {
  return (
    <div className='maintenance-card'>
      <span className='maintenance-card-image snow'></span>
      <span className='maintenance-card-title'>Snow Removal</span>
      <span className='maintenance-card-verbiage'>
        Snow removal begins right after snowing stops, and includes the adjacent sidewalks, outside staircases and other areas belonging to the building we service. We do a through job making sure the walkways are safe and clean. We have crews on standby and are well prepared for snow days.
      </span>
    </div>
  )
}

export default Snow