import React, { useState } from 'react'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Landing, Home, About, InteriorCleaning, Office, Garbage, Snow, Construction, Renovation } from './containers'
import backgroundVideoMP4 from '../../images/carousel0.mp4';
import backgroundVideoWEBM from '../../images/carousel0.webm';
import './carousel.css';


const Carousel = () => {
    const carouselArray = [
        { 'slideName': 'home', 'imgSrc': 'carousel-image-landing', 'cssClass': 'mp4bg', 'carouselHtml': <Landing /> },
        { 'slideName': 'home', 'imgSrc': 'carousel-image-home', 'cssClass': '', 'carouselHtml': <Home /> },
        { 'slideName': 'about', 'imgSrc': 'carousel-image-about', 'cssClass': '', 'carouselHtml': <About /> },
        { 'slideName': 'buildings', 'imgSrc': 'carousel-image-maintenance', 'cssClass': '', 'carouselHtml': <InteriorCleaning /> },
        { 'slideName': 'officeSpace', 'imgSrc': 'carousel-image-office', 'cssClass': '', 'carouselHtml': <Office /> },
        { 'slideName': 'garbage', 'imgSrc': 'carousel-image-garbage', 'cssClass': '', 'carouselHtml': <Garbage /> },
        { 'slideName': 'snow', 'imgSrc': 'carousel-image-snow', 'cssClass': '', 'carouselHtml': <Snow /> },
        { 'slideName': 'construction', 'imgSrc': 'carousel-image-construction', 'cssClass': 'construction-css', 'carouselHtml': <Construction /> },
        { 'slideName': 'renovation', 'imgSrc': 'carousel-image-post', 'cssClass': '', 'carouselHtml': <Renovation /> },
    ];

    const [count, setCount] = useState(1);

    function decrementCount() {
        if (count > 1) {
            setCount(count - 1);
        } else {
            setCount(carouselArray.length);
        }
    }
    function incrementCount() {
        if (count >= 0 && count < carouselArray.length) {
            setCount(count + 1);
        } else {
            setCount(1);
        }
    }

    return (
        <>
            <div className='carousel-container'>
                {carouselArray.map((slide, index) => {
                    return (
                        <div className={(index + 1) === count ? ' slide-wrapper active ' + slide.cssClass : ' slide-wrapper ' + slide.cssClass} id={index} key={index}>
                            {slide.cssClass === 'mp4bg' ?
                                <>
                                    <div className='slide-img'>
                                        <video className='slide-img' src={backgroundVideoMP4} muted autoPlay loop playsInline data-wf-ignore="true" data-object-fit="cover">
                                            <source src={backgroundVideoMP4} data-wf-ignore="true" />
                                            <source src={backgroundVideoWEBM} data-wf-ignore="true" />
                                        </video>
                                    </div>
                                    <div className='carousel-body'>{slide.carouselHtml}</div>
                                </>
                                :
                                <>
                                    <div className={'slide-img ' + slide.imgSrc}></div>
                                    <div className='carousel-body'>{slide.carouselHtml}</div>
                                </>
                            }


                        </div>
                    );
                })}
            </div>

            <span className='carousel-arrow left' onClick={decrementCount}><FaAngleLeft /></span>
            <span className='carousel-arrow right' onClick={incrementCount}><FaAngleRight /></span>
        </>

    );
}

export default Carousel