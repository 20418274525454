import React from 'react'
import '../services.css';

const Apartment = () => {
    return (
        <div className='services-card'>
            <span className='services-card-image apartment'></span>
            <span className='services-card-title'>Apartment Cleaning</span>
            <span className='services-card-verbiage'>
                Whether it is an occasional professional cleaning, or a necessary deep cleaning. Ryne offers a comprehensive cleaning service done by trained and experienced associates. You will love your place all over again with that fresh smell and the sparkling look. We are here to impress you.
            </span>
        </div>
    )
}

export default Apartment