import React from 'react'
import '../index.css';
import './about.css'

const About = () => {
    const maintenanceClick = () => {
        document.querySelectorAll("a[href='#/maintenance']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }
    return (
        <div className='carousel-wrapper'> <span className='carousel-line1'>Your Common Areas Spick and Span!</span>
            <span className='carousel-line2'>You will be happy with our services, and your tenants will be delighted to call your buildings their home!</span>
            <span className='carousel-line3'>Keeping your common areas fresh, clean and clutter free is our specialty.</span>
            <span className='carousel-button' onClick={maintenanceClick}>Read more</span>
        </div>
    )
}

export default About