import React from 'react';
import '../construction.css';

const Inspection = () => {
  return (
    <div className='page-content-wrapper'>
      <div className='construction-card-wrapper'>
        <div className='construction-card construction-mobile-bottom'>
          <span className='construction-card-title'>Inspection Ready</span>
          <span className='construction-card-verbiage'>
            Along with all other construction services we provide, getting a place ready for an inspector is also something we are great at. We have done it many times over keeping our customer happy with great outcomes.
          </span>
          {/* <span className='construction-card-verbiage'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
          </span> */}
        </div>

        <div className='construction-card construction-mobile-top'>
          <span className='construction-card-image inspection'></span>
        </div>
      </div>
    </div>
  )
}

export default Inspection