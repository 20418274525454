import React from 'react'
import '../index.css';
import './home.css';

const Home = () => {
  return (
    <div className='carousel-wrapper'>
      <span className='carousel-line1'>Need it Spotless Clean ASAP?</span>
      <span className='carousel-line2'>New apartments, commercial facilities, renovation, post construction and so much more, reach out to us.</span>
      <span className='carousel-line3'>Brace yourself for a blazingly fast and professional service!</span>
    </div>
  )
}

export default Home