import React from 'react'
import '../index.css';
import './landing.css';

const Landing = () => {
    const contactClick = () => {
        document.querySelectorAll("a[href='#/contact']")[0].click();
        setTimeout(() => {
            document.getElementsByClassName('navbar-active-animate')[0].classList.remove('toggle-on');
        }, 10);
    }

    return (
        <div className='carousel-wrapper mp4-opacity'>
            <span className='carousel-line1'>Building Cleaning and Maintenance<br /> services you can rely on!</span>
            <span className='carousel-line2'>Services we provide<br />  • Construction and renovation cleanings<br />  • Deep cleaning<br />  • Disinfecting<br />  • Scheduled cleanings<br />  • Building maintenance and more!</span>
            <span className='carousel-line3'>We will customize a plan that meets your standards to the last detail and finest points!</span>
            <span className='carousel-button' onClick={contactClick}>Let's get in touch</span >
        </div>
    )
}

export default Landing