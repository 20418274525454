import React from 'react'
import { FaLinkedinIn, FaInstagram, FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";

import './footer.css'


const Footer = () => {
    return (
        <div className='footer-wrapper'>
            <div className='footer-container'>
                <span className='footer-logo'>Copyright Ryne Cleaning &copy; {(new Date().getFullYear())}</span>
                <div className='footer-col-wrapper'>
                    <div className='footer-col'>
                        <span className='footer-col-title'>Services</span>
                        <div className='footer-col-content'>
                            <span className='footer-col-row link'>Building Maintenance and Upkeep</span>
                            <span className='footer-col-row link'>Mid Construction Cleaning </span>
                            <span className='footer-col-row link'>Post Construction Cleaning</span>
                            <span className='footer-col-row link'>Office Cleaning</span>
                            <span className='footer-col-row link'>Apartment Turnover</span>
                        </div>
                    </div>
                    <div className='footer-col'>
                        <span className='footer-col-title'>Contact Info</span>
                        <div className='footer-col-content'>
                            <span className='footer-col-row'>199 Lee Avenue, #998</span>
                            <span className='footer-col-row'>Brooklyn, NY 11211</span>
                            <span className='footer-col-row'>info@rynecleaning.com</span>
                            <span className='footer-col-row'>718 427 2111</span>
                        </div>
                    </div>
                    <div className='footer-col'>
                        <span className='footer-col-title'>Social</span>
                        <div className='footer-col-content'>
                            <a className='footer-col-row link' href='https://www.linkedin.com/company/ryne-cleaning-&-maintenance-inc-/' target="_blank"><FaLinkedinIn />LinkedIn</a>
                            <a className='footer-col-row link' href='https://instagram.com/ryneclean?utm_source=ig_profile_share&igshid=130rmz0irrlq6' target='_blank'><FaInstagram />Instagram</a>
                            <a className='footer-col-row link' href='https://www.facebook.com/Ryne-Cleaning-Maintenance-101549492559007' target="_blank"><FaFacebookF />Facebook</a>
                            <a className='footer-col-row link' href='https://twitter.com/rynecleaning' target="_blank"><FaTwitter />Twitter</a>
                            <a className='footer-col-row link' href='https://wa.me/17184272111?text=' target="_blank"><FaWhatsapp />718 427 2111</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer