import React, { useState } from 'react';
import ProgressBar from './ProgressBar';

const UploadImages = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const types = ['image/png', 'image/jpeg'];

  const handleChange = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError('');
    } else {
      setFile(null);
      setError('Please select an image file (png or jpg)');
    }
  };

  const handleTitle = (e) => {
    let titleText = e.target.value;

    if (titleText) {
      setTitle(titleText)
    } else {
      setTitle('');

    }
  }

  const handleDescription = (e) => {
    let descriptionText = e.target.value;

    if (descriptionText) {
      setDescription(descriptionText)
    } else {
      setDescription('');

    }

  }

  return (
    <form>
      <label>
        <input type="text" placeholder='title' onChange={handleTitle} />
        <input type="text" placeholder='brief description' onChange={handleDescription} />
        <input type="file" onChange={handleChange} />
      </label>
      <div className="output">
        {error && <div className="error">{error}</div>}
        {file && <div>{file.name}</div>}
        {file && <ProgressBar file={file} setFile={setFile} title={title} description={description} />}
      </div>
    </form>
  );
}

export default UploadImages;